<template>
  <VFlex
    :class="['menu-item--addons', { 'is-dirty': isDirty }]"
    xs12
    row
    @mouseover.stop="isHovering = true"
    @mouseleave.stop="isHovering = false"
  >
    <transition name="fade-fastest" mode="out-in">
      <ButtonNewAddon
        v-if="!addons.length && isItemSelected"
        @click="$emit('newAddon')"
      />
      <transition-group
        v-else-if="addons.length"
        tag="div"
        class="layout row wrap"
        name="addon-list"
      >
        <BaseMenuComponent
          v-for="(addon, i) of addons"
          :key="addon.id"
          :ref="`addon-${i}`"
          name="MenuItemAddon"
          :addon="{ ...addon, ...{ isLast: i === addons.length - 1 } }"
          @remove="$emit('removeAddon', addon)"
          @updateAddon="
            $emit('updateAddon', { addon, key: $event.key, val: $event.val })
          "
        />
        <BaseButtonIconMini
          v-if="(isShowingEditUI && isHovering) || isItemSelected"
          key="add-new-btn"
          class="ml-1"
          text-color="white"
          color="bloak-pink"
          xs
          @click="$emit('newAddon')"
        />
      </transition-group>
    </transition>
  </VFlex>
</template>

<script>
import { mapGetters } from 'vuex'

import ButtonNewAddon from '@editor/ButtonNewAddon'

export default {
  name: 'BlueOakMenuItemAddons',
  components: {
    ButtonNewAddon,
  },
  props: {
    addons: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDirty: {
      type: Boolean,
      default: false,
    },
    isItemSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovering: false,
    }
  },
  computed: {
    ...mapGetters('editor', ['isShowingEditUI']),
  }, // computed
  methods: {},
}
</script>

<style lang="scss">
@import '@design';
.is-dirty {
  min-height: 10px;
}
.addon-list-enter-active,
.addon-list-leave-active {
  transition: all 0.2s;
}
.addon-list-enter, .addon-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateX(10px);
}
</style>
